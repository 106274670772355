import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignatureDrawModalComponent } from '../signature-draw-modal/signature-draw-modal.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import SignaturePad from 'signature_pad';

@Component({
  standalone: false,
  selector: 'app-signature-modal',
  templateUrl: './signature-modal.component.html',
  styleUrls: ['./signature-modal.component.css']
})
export class SignatureModalComponent implements OnInit {

  @Output() onConfirmResult = new EventEmitter();

  signPad:any;
  signatureName:string = '';
  preview: any;
  resultFile: File | undefined;
  isSelectedFile = false;
  isDrawSignature = true;
  
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    setTimeout(() => { this.setupSignaturePad() }, 300);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setupSignaturePad();
  }

  setupSignaturePad(){
    const canvas:any = document.querySelector("canvas");
    if(canvas){
      var ratio =  Math.max(window.devicePixelRatio || 0.9, 0.9);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext("2d").scale(ratio, ratio);
  
      this.signPad = new SignaturePad(canvas, {
        // It's Necessary to use an opaque color when saving image as JPEG;
        // this option can be omitted if only saving as PNG or SVG
        backgroundColor: 'rgb(240, 240, 240)'
      });
      this.signPad.addEventListener("beginStroke", () => {
        // console.log("Signature started");
      }, { once: true });
  
      // This part causes the canvas to be cleared
      this.signPad.clear();
    }
  }

  async onFileSelect(value: any) {
    if(value.target.files[0].size > 2000000){
      this.toastrService.warning(this.translate.instant('formbuilder.max-size-is') + ' ' + 2 + 'MB.');
    } else {
      if (value.target.files[0]) {
        this.isDrawSignature = false;
        this.isSelectedFile = true;
        const file: File = value.target.files[0];
  
        if (file) {
          this.processImagePreview(file);
          this.resultFile = file;
        }
      }
    }
  }

  onDrawSignBtnClick(){
    this.isSelectedFile = false;
    this.isDrawSignature = true;
    setTimeout(() => { this.setupSignaturePad() }, 300);
  }

  onResetClick(){
    this.signPad.clear();
  }

  onConfirmClick() {
    const signature = this.signPad.toDataURL("image/jpeg");
    const file = this.imageUrlToFile(signature);
    this.resultFile = file;

    this.onConfirmResult.emit({status: true, data: this.resultFile});
    this.activeModal.close({status: true, data: this.resultFile});
  }

  onCancelClick() {
    this.onConfirmResult.emit({status: false, data: undefined});
    this.activeModal.close({status: false, data: undefined});
  }

  processImagePreview(file:File){
    this.preview = '';
    let currentFile = file;

    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.preview = e.target.result;
    };

    reader.readAsDataURL(currentFile);
  }

  imageUrlToFile(data: any) {
    const arr = data.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file: File = new File([u8arr], 'signature.jpeg', { type: 'image/jpeg' })
    return file;
  }

}
