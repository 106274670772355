import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { Permission, UserPermission } from 'src/app/interfaces/masterpass/policy';
import { ErrorHandlerService } from '../utilities/error-handler.service';

const PERMISSION_KEY = 'user-permission'

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  permissionEndPoint = "/permission/";

  constructor(private http: HttpClient, private errorHandlerService: ErrorHandlerService) { }

  async getPermission(id: string): Promise<Observable<any>> {
    return this.http.get<any>(environment.apiUrl + this.permissionEndPoint + id);
  }

  async getAllPermission(): Promise<Observable<any>> {
    return this.http.get<any>(environment.apiUrl + this.permissionEndPoint);
  }

  getAllPermissions(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + this.permissionEndPoint);
  }

  async createPermission(data: Permission): Promise<Observable<any>> {
    return this.http.post<any>(environment.apiUrl + this.permissionEndPoint, data);
  }

  async updatePermission(data: Permission): Promise<Observable<any>> {
    return this.http.patch<any>(environment.apiUrl + this.permissionEndPoint, data);
  }

  async deletePermission(id: string): Promise<Observable<any>> {
    return this.http.delete<any>(environment.apiUrl + this.permissionEndPoint + id);
  }

  // User Organisation Permissions
  getUserOrganisationPermission(userId: string): Observable<any> {
    return this.http.get<any>(environment.apiUrl + this.permissionEndPoint + 'user-organisation/' + userId);
  }

  updateUserOrgPermissions(userId: string, data: any): Observable<any> {
    return this.http.patch<any>(environment.apiUrl + this.permissionEndPoint + "user-organisation/" + userId, data);
  }

  // Organisation Permissions
  getOrgPermissions(id: any): Observable<any> {
    return this.http.get<any>(environment.apiUrl + this.permissionEndPoint + "org_permission/" + id);
  }

  updateOrgPermissions(id: any, data: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + this.permissionEndPoint + "org_permission/" + id, data);
  }

  // Organisation Invite Permissions
  getOrgInvitationPermission(id: any): Observable<any> {
    return this.http.get<any>(environment.apiUrl + this.permissionEndPoint + "organisation-invitation/" + id);
  }

  updateOrgInvitationPermission(id: any, data: any): Observable<any> {
    return this.http.patch<any>(environment.apiUrl + this.permissionEndPoint + "organisation-invitation/" + id, data);
  }

  // Department Permissions
  getDepartmentPermissions(id: any): Observable<any> {
    return this.http.get<any>(environment.apiUrl + this.permissionEndPoint + "department/" + id);
  }

  updateDepartmentPermissions(id: any, data: any): Observable<any> {
    return this.http.patch<any>(environment.apiUrl + this.permissionEndPoint + "department/" + id, data);
  }

  // Role Permissions
  getRolePermissions(id: any): Observable<any> {
    return this.http.get<any>(environment.apiUrl + this.permissionEndPoint + "role/" + id);
  }

  updateRolePermissions(id: any, data: any): Observable<any> {
    return this.http.patch<any>(environment.apiUrl + this.permissionEndPoint + "role/" + id, data);
  }

  /**
   * The function clears a user's permission by removing an item from the local storage.
   */
  public clearUserPermission() {
    window.localStorage.removeItem(PERMISSION_KEY);
  }

  /**
   * The function saves a user's permission to the local storage as a JSON string.
   * @param {any} permission - The parameter "permission" is of type "any", which means it can accept
   * any data type. It is used to store the permission information for a user.
   * @returns nothing (undefined).
   */
  public saveUserPermission(permission: any) {
    // console.log(permission)
    if (!permission || permission == 'undefined') return;
    window.localStorage.removeItem(PERMISSION_KEY);
    window.localStorage.setItem(PERMISSION_KEY, JSON.stringify(permission));
  }

  public getUserPermission(): any {
    const permissions = window.localStorage.getItem(PERMISSION_KEY);
    if (permissions) {
      return JSON.parse(permissions);
    }
    return [];
  }
}
