@if (!isHideTopbar) {
  <div
    class="topbar ms-5"
    style="position: absolute; top: 0; right: 10px; z-index: 100;">
    <ul>
      <!-- Organisation Switcher -->
      <li class="mt-1">
        @if (!orgLoading) {
          <select
            class="form-select bg-light py-2 px-5 rounded border-0"
            (change)="onOrgChange($event)">
            @for (org of (organisationsList); track org) {
              <option
                [selected]="org.id === currentOrganisation?.id"
                [value]="org | json">
                {{org.name}}
              </option>
            }
          </select>
        }
      </li>
      <!-- Notification Dropdown -->
      <li>
        <app-notification></app-notification>
      </li>
      <!-- Profile Dropdown -->
      <li>
        <div ngbDropdown #profileDropdown="ngbDropdown" class="dropdown-lg">
          <button class="btn btn-default m-1 p-1" style="line-height: 1;" href="#" ngbDropdownToggle>
            <i class="bi-person fs-4"></i>
          </button>
          <div ngbDropdownMenu class="bg-light pb-0">
            <div class="bg-dropdown-profile d-flex flex-row-reverse align-items-center">
              <img [src]="userData?.imgUrl"
                [default]="'../../../../../assets/identicon.png'"
                style="width: 70px;height: 70px; opacity: 1; z-index: 3; top: 20px; left: 20px;" alt=""
                class="profile-pic position-absolute border border-white mb-3 mt-3">
              <p class="mb-0 fw-bolder text-white me-3 w-50">
                {{userData?.name}} -
                {{userData?.role?.roles}}
                @if (userData.owner) {
                  <br>
                  }
                  @if (userData.owner) {
                    <span class="badge bg-primary">
                      {{'navigation.owner' | translate}}
                    </span>
                  }
                </p>
              </div>
              <div class="position-relative" style="z-index: 1; margin-top:110px">
                <div class="d-flex flex-column align-items-center mx-0">
                  <div (click)="onNavigateClick('profile', false); profileDropdown.close();"
                    class="d-flex flex-row bg-navbar-action p-4">
                    <i class="bi bi-person me-3 fs-5"></i>
                    <span class="fw-bolder">{{'navigation.profile' | translate}}</span>
                  </div>
                  <!-- <div (click)="onNavigateClick('setting'); profileDropdown.close();" class="d-flex flex-row justify-content-between p-4 rounded-pill bg-navbar-action" style="width: 12rem">
                  <div>
                    <i class="bi bi-gear me-2"></i>
                    <span class="fw-bolder">Settings</span>
                  </div>
                  <i class="bi bi-chevron-right"></i>
                </div> -->
                <div (click)="onNavigateClick('user-guide', false); profileDropdown.close();"
                  class="d-flex flex-row bg-navbar-action p-4">
                  <i class="bi bi-question-circle me-3 fs-5"></i>
                  <span class="fw-bolder">{{'navigation.help' | translate}}</span>
                </div>
                <div (click)="onLogoutClick()"
                  class="d-flex flex-row bg-navbar-action p-4">
                  <i class="bi bi-box-arrow-right me-3 fs-5"></i>
                  <span class="fw-bolder">{{'navigation.logout' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
}

<!-- Sidebar -->

@if (!isHideSidebar) {
  <div id="sidebar"
    class="sidebar d-flex flex-column justify-content-between"
    [ngClass]="{'sidebar-closed' : isMinimized}">
    <div>
      <div class="sidebar-close">
        <img src="/assets/icons/dashboard/minimize-arrow.svg" class="sidebar-close-icon" (click)="toggleSidebar()">
      </div>
      <br><br>
      <img id="logo-icon" src="/assets/logos/logo_portrait_transparent.svg" class="logo-img cursor-pointer" (click)="onNavigateClick('dashboard')">
      <img id="logo-icon-only" style="display:none" src="/assets/logos/logo_only_transparent.svg" class="logo-img cursor-pointer" (click)="onNavigateClick('dashboard')">
      <div class="sidebar-content">
        @for (optionInfo of visibleSystemOption; track optionInfo) {
          <!--
          Displaying parent menu item / menu without children
          Conditional: Check permission access
          clickedOption -> Used to check active or not
          clickedParent -> To tell which parent menu should be opened
          childrenMenu -> Submenus for the corresponding parent item
          -->
          @if (utilityService.checkPermission(optionInfo.resource)) {
            <div class="py-1"
              [ngStyle]="{'filter': navigateLimiter ? 'opacity(0.9)' : 'opacity(1.0)'}">
              <app-sidebar-button
                [isMinimized]="isMinimized"
                [optionInfo]="optionInfo"
                [clickedOption]="clickedOption"
                [clickedParent]="isMinimized ? true : visibleParent.includes(optionInfo.linkText)"
                [childrenMenu]="visibleParent.includes(optionInfo.linkText) ? visibleSystemOptionChildren[findParentIndex(optionInfo.linkText)] : []"
                (onClickEvent)="onClickEvent($event)">
              </app-sidebar-button>
            </div>
          }
          <!--
          Displaying child menu items,
          Conditional: Hide when sidebar is minimzed
          Repeat: Loop child items
          Conditional: Check permission access
          -->
          @if (visibleParent.includes(optionInfo.linkText) && !isMinimized) {
            @for (optionInfo of visibleSystemOptionChildren[findParentIndex(optionInfo.linkText)]; track optionInfo) {
              @if (utilityService.checkPermission(optionInfo.resource)) {
                <div [ngStyle]="{'filter': navigateLimiter ? 'opacity(0.9)' : 'opacity(1.0)'}">
                  <app-sidebar-button
                    [optionInfo]="optionInfo"
                    [clickedOption]="clickedOption"
                    (onClickEvent)="onClickEvent($event)">
                  </app-sidebar-button>
                </div>
              }
            }
          }
        }
      </div>
      <!-- Organisation name and uen -->
      <hr class="mx-3">
      <div class="w-100" style="overflow-x: hidden;">
        @if (!isMinimized) {
          <h3 class="org-title text-dark text-start fw-bold fs-6 mb-1"
            [ngClass]="{'scroll-text': currentOrganisation?.name.length > 18}">
            {{currentOrganisation?.name}}
            @if (currentOrganisation?.name.length > 18) {
              {{currentOrganisation?.name}}
            }
          </h3>
        }
        @if (isMinimized) {
          <h3 class="org-title text-dark text-start fw-bold fs-7 mb-1"
            [ngClass]="{'scroll-text': true}">
            {{currentOrganisation?.name}}
            @if (currentOrganisation?.name.length > 12) {
              {{currentOrganisation?.name}}
            }
          </h3>
        }
      </div>
      <span class="badge rounded-pill bg-warning fs-6 py-1 mb-2"
        [ngClass]="{'fs-6 px-3': !isMinimized, 'fs-8 px-1': isMinimized}">
        {{ currentOrganisation?.uen || '' }}
      </span>
    </div>
    <!-- Footer: Language selector -->
    <div id="layout-footer" class="mx-3 mt-2 mb-4">
      <hr class="mx-3">
      <div ngbDropdown #languageDropdown="ngbDropdown" placement="top" class="px-2 dropdown-sm hover-visible">
        <p class="text-start fs-7 text-primary mb-1" ngbDropdownToggle>
          {{'navigation.language' | translate}}
          <i class="bi bi-chevron-up"></i>
        </p>
        <div ngbDropdownMenu class="pt-0 pb-0 mb-0" style="border-radius: 0.8rem;">
          @for (item of languagesList; track item) {
            <div
              (click)="onLanguageSelectChange(item.code); languageDropdown.close();"
              class="d-flex flex-row justify-content-between p-3 px-4 me-3 bg-language-select"
              style="width: 10rem">
              <div>
                <span class="fw-bold">{{item.nativeName}}</span>
              </div>
            </div>
          }
        </div>
      </div>
      <p class="fs-7 fw-bold text-primary text-start ps-2">
        {{selectedLanguageName || '-'}}
      </p>
    </div>
  </div>
}

<!-- Navigation Modal -> only in mobile view -->
<div id="toggle-mobile-menu" style="z-index: 1000;" (click)="openModal()">
  <img src="../../assets/icons/icons8-menu-24.png">
</div>

<!-- The Modal -->
<div id="sidebar-modal" class="sidebar-modal">
  <div class="sidebar-modal-content p-0">
    <div class="modal-header bg-info2 p-4 pb-2 text-primary">
      <h2 class="modal-title m-0 fw-bold" id="clockInLabel">
        {{'navigation.navigation' | translate}}
      </h2>
      <button type="button" class="btn-close p-0 m-0" (click)="closeModal()"></button>
    </div>
    <div class="sidebar-modal-list px-4">
      <ul>
        @for (optionInfo of visibleSystemOption; track optionInfo) {
          <li class="sidebar-modal-list-item my-2"
            >
            <app-navigation-modal-button
              [optionInfo]="optionInfo"
              [clickedOption]="clickedOption"
              (onClickEvent)="onClickEvent($event, true)">
            </app-navigation-modal-button>
          </li>
        }

        @if (visibleSystemOptionChildren[findParentIndex(clickedOption)]?.length > 0) {
          <li class="sidebar-modal-list-item my-2 pb-2 px-3 fs-7">
            <div class="sidebar-parent" (click)="visibleSystemOptionChildren = []">
              <div class="sidebar-text fw-bold w-100 text-start">
                <i class="bi-chevron-up"></i>
                {{'others.close' | translate}}
              </div>
            </div>
          </li>
        }
        @for (optionInfo of visibleSystemOptionChildren[findParentIndex(clickedOption)]; track optionInfo) {
          <li class="sidebar-modal-list-item my-2 pb-2 px-3 fs-7"
            >
            <app-navigation-modal-button
              [optionInfo]="optionInfo"
              [clickedOption]="clickedOption"
              (onClickEvent)="onClickEvent($event, true)">
            </app-navigation-modal-button>
          </li>
        }
      </ul>
    </div>
  </div>
</div>