<div class="container p-4 mt-4">
    <div id="overview-content" class="position-relative">
 
        <ng-container *ngIf="isLoading">
          <app-loading-indicator></app-loading-indicator>
        </ng-container>

        <ng-container *ngIf="!isLoading">

            <div class="row">
                <div class="col-2"></div>

                <div class="col-8">
                    <div class="row mb-4" style="min-width: 210mm;">
                        <div class="d-flex justify-content-between">
                            <button class="btn btn-default" (click)="onBackClick()">
                                <i class="bi-arrow-left"></i> {{'others.back' | translate | titlecase}}
                            </button>
                            <button (click)="onExportClick()" class="btn btn-primary p-3">
                                <i class="bi-download me-2"></i>
                                {{'payroll.save-payslip' | translate}}
                            </button>
                        </div>
                    </div>

                    <div class="rounded p-2 pt-3 w-100">
                        <div id="export" [style]="textStyle">

                            <div class="row my-3 mb-5 mx-0">
                                <div class="col">
                                    <div class="p-3 bg-primary" 
                                        style="width: 120px;">
                                        <img [src]="'../../../../../assets/logos/logo_white.png'"
                                            class="img-fluid ps-3">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="btn-group float-end mt-3">
                                        <button [style]="textStyle"
                                            class="btn border-primary bg-white bg-info2 cursor-default pt-0 fs-7">
                                            {{'claims.date' | translate}}
                                        </button>
                                        <button [style]="textStyle"
                                            class="btn border-primary bg-white fw-bold cursor-default pt-0 fs-7">
                                            {{data.payroll.startDate * 1000 | date: 'dd MMMM yyyy'}}
                                            - {{data.payroll.endDate * 1000 | date: 'dd MMMM yyyy'}}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="row my-3 mx-0">
                                <h2 [style]="textStyle" class="text-center fw-bold"> 
                                    {{'payroll.payslip' | translate | uppercase}}
                                    {{data.payroll.startDate * 1000 | date: 'MMMM yyyy' | uppercase}}
                                </h2>
                            </div>

                            <div class="row my-3 mx-0">
                                <div class="col">
                                    <table>
                                        <tr>
                                            <td [style]="textStyle"
                                                [width]="150">
                                                {{'profile.name' | translate}}
                                            </td>
                                            <td [style]="textStyle"
                                                class="fw-bold py-2 pb-1">
                                                {{staffData?.name || '-'}} 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td [style]="textStyle"
                                                class="fw-bold py-2 pt-1">
                                                {{staffData?.id || '-'}} 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td [style]="textStyle">{{'policies.department' | translate}}</td>
                                            <td [style]="textStyle" 
                                                class="fw-bold py-2">
                                                {{staffData?.department.name || '-'}} 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td [style]="textStyle">{{'policies.role' | translate}}</td>
                                            <td [style]="textStyle" 
                                                class="fw-bold py-2">
                                                {{staffData?.role.roles || '-'}} 
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div class="row my-4 mx-0">
                                <div class="col">
                                    <table class="w-100 table-striped-payslip">
                                        <thead>
                                            <tr class="bg-primary">
                                                <td [style]="'border-top-left-radius: 20px;' + textStyle" 
                                                    [width]="'50%'"
                                                    class="fw-bold text-center text-white p-3">
                                                    {{'payroll.earnings' | translate}}
                                                </td>
                                                <td [style]="'border-top-right-radius: 20px;' + textStyle" 
                                                    [width]="'50%'"
                                                    class="fw-bold text-center text-white p-3">
                                                    {{'payroll.amount' | translate}} (SGD)
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td [style]="textStyle"  
                                                    class="p-3">
                                                    {{'payroll.base-salary' | translate}}
                                                </td>
                                                <td [style]="textStyle" 
                                                    class="p-3 text-end pe-10">
                                                    {{data.baseSalary | currency:'S$'}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td [style]="textStyle" 
                                                    class="p-3">
                                                    {{'payroll.overtime-salary' | translate}}
                                                </td>
                                                <td [style]="textStyle" 
                                                    class="p-3 text-end pe-10">
                                                    {{data.overtimeSalary | currency:'S$'}}
                                                </td>
                                            </tr>
                                            <ng-container *ngFor="let item of payslipData;">
                                                <tr *ngIf="item.payComponentConfig.type === 'EARNING'" >
                                                    <td [style]="textStyle" 
                                                        class="p-3">
                                                        {{item.payComponentConfig.name}}
                                                    </td>
                                                    <td [style]="textStyle" 
                                                        class="p-3 text-end pe-10">
                                                        {{item.amount | currency:'S$'}}
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            <tr style="background-color: #E2ECF8;">
                                                <td [style]="'border-bottom-left-radius: 20px;' + textStyle" 
                                                    class="p-3 fw-bold">
                                                    {{'payroll.total-earnings' | translate | uppercase}}
                                                </td>
                                                <td [style]="'border-bottom-right-radius: 20px;' + textStyle" 
                                                    class="p-3 fw-bold text-end pe-10">
                                                    {{data.totalEarnings | currency:'S$'}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="row my-4 mx-0">
                                <div class="col">
                                    <table class="w-100 table-striped-payslip">
                                        <thead>
                                            <tr class="bg-primary">
                                                <td [style]="'border-top-left-radius: 20px;' + textStyle" 
                                                    [width]="'50%'"
                                                    class="fw-bold text-center text-white p-3">
                                                    {{'payroll.deductions' | translate}}
                                                </td>
                                                <td [style]="'border-top-right-radius: 20px;' + textStyle" 
                                                    [width]="'50%'"
                                                    class="fw-bold text-center text-white p-3">
                                                    {{'payroll.amount' | translate}} (SGD)
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let item of payslipData;">
                                                <tr *ngIf="item.payComponentConfig.type === 'DEDUCTION'" >
                                                    <td [style]="textStyle" 
                                                        class="p-3">
                                                        {{item.payComponentConfig.name}}
                                                    </td>
                                                    <td [style]="textStyle" 
                                                        class="p-3 text-end pe-10">
                                                        {{item.amount | currency:'S$'}}
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            <ng-container *ngIf="data.nDeduction === 0">
                                                <tr>
                                                    <td [style]="textStyle" 
                                                        class="p-3">
                                                        -
                                                    </td>
                                                    <td [style]="textStyle" 
                                                        class="p-3 text-end pe-10">
                                                        {{0 | currency:'S$'}}
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            <tr style="background-color: #E2ECF8;">
                                                <td [style]="'border-bottom-left-radius: 20px;' + textStyle" 
                                                    class="p-3 fw-bold">
                                                    {{'payroll.total-deductions' | translate | uppercase}}
                                                </td>
                                                <td [style]="'border-bottom-right-radius: 20px;' + textStyle" 
                                                    class="p-3 fw-bold text-end pe-10">
                                                    {{data.totalDeduction | currency:'S$'}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="row my-4 mx-0">
                                <div class="col">
                                    <table class="w-100 table-striped-payslip">
                                        <thead>
                                            <tr class="bg-primary">
                                                <td [style]="'border-top-left-radius: 20px;' + textStyle" 
                                                    [width]="'50%'"
                                                    class="fw-bold text-center text-white p-3">
                                                    {{'payroll.net-salary' | translate}}
                                                </td>
                                                <td [style]="'border-top-right-radius: 20px;' + textStyle" 
                                                    [width]="'50%'"
                                                    class="fw-bold text-center text-white p-3">
                                                    {{'payroll.amount' | translate}} (SGD)
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td [style]="textStyle" 
                                                    class="p-3">
                                                    {{'payroll.total-earnings' | translate}}
                                                </td>
                                                <td [style]="textStyle"  
                                                    class="p-3 text-end pe-10">
                                                    {{data.totalEarnings | currency:'S$'}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td [style]="textStyle" 
                                                    class="p-3">
                                                    {{'payroll.total-deductions' | translate}}
                                                </td>
                                                <td [style]="textStyle"  
                                                    class="p-3 text-end pe-10">
                                                    {{data.totalDeduction | currency:'S$'}}
                                                </td>
                                            </tr>
                                            <tr style="background-color: #E2ECF8;">
                                                <td [style]="'border-bottom-left-radius: 20px;' + textStyle" 
                                                    class="p-3">
                                                    {{'payroll.net-salary' | translate}}
                                                </td>
                                                <td [style]="'border-bottom-right-radius: 20px;' + textStyle" 
                                                    class="p-3 text-end pe-10">
                                                    {{data.totalSalary | currency:'S$'}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="d-flex flex-column justify-content-between">
                                <div class="row mx-2">
                                    <div class="col d-flex">
                                        <p class="fs-6" [style]="textStyle">
                                            {{'payroll.footer-text-1' | translate}}
                                        </p>
                                        <p class="fs-6 fw-bold ms-2" [style]="textStyle">
                                            {{'superink@hr.sg'}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>

        </ng-container>
 
    </div>
 </div>
 
 