import { InformationSummaryComponent } from './information-summary/information-summary.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { TablePaginationComponent } from './table-pagination/table-pagination.component';
import { FileViewerModalComponent } from './file-viewer-modal/file-viewer-modal.component';
import { TableComponent } from './table/table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { ImagePreloadDirective } from '../directives/image-preload.directive';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NoAccessComponent } from './no-access/no-access.component';
import { InformationModalComponent } from './information-modal/information-modal.component';
import { ConversationModalComponent } from './conversation-modal/conversation-modal.component';
import { CameraModalComponent } from './camera-modal/camera-modal.component';
import { WebcamModule } from 'ngx-webcam';
import { LocationViewerModalComponent } from './location-viewer-modal/location-viewer-modal.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TimeagoPipe } from './timeago.pipe';
import { Timeago2Pipe } from './timeago2.pipe';
import { NgxDatePipe } from './date.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { environment } from 'src/environments/environment';
import { NavigationTabsComponent } from './navigation-tabs/navigation-tabs.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationModalButtonComponent } from './navigation/navigation-modal-button/navigation-modal-button.component';
import { RouterModule } from '@angular/router';
import { FileDragDropDirective } from '../directives/file-drag-drop.directive';
import { SignatureModalComponent } from './signature-modal/signature-modal.component';
import { SignatureDrawModalComponent } from './signature-draw-modal/signature-draw-modal.component';
import { WorkflowViewerComponent } from './workflow-viewer/workflow-viewer.component';

const env = environment;

@NgModule({
  declarations: [
    InformationSummaryComponent,
    ConfirmModalComponent,
    TablePaginationComponent,
    FileViewerModalComponent,
    TableComponent,
    LoadingIndicatorComponent,
    ImagePreloadDirective,
    FileDragDropDirective,
    UnderConstructionComponent,
    NotFoundComponent,
    NoAccessComponent,
    InformationModalComponent,
    ConversationModalComponent,
    CameraModalComponent,
    LocationViewerModalComponent,
    AutocompleteComponent,
    TimeagoPipe,
    Timeago2Pipe,
    NgxDatePipe,
    NavigationTabsComponent,
    NavigationModalButtonComponent,
    SignatureModalComponent,
    SignatureDrawModalComponent,
    WorkflowViewerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    WebcamModule,
    MatAutocompleteModule,
    TranslateModule,
    NgbModule,
    RouterModule,
    NgxMapboxGLModule.withConfig({
      accessToken: env.mapboxToken,
    })
  ],
  exports: [
    InformationSummaryComponent,
    ConfirmModalComponent,
    TablePaginationComponent,
    FileViewerModalComponent,
    TableComponent,
    LoadingIndicatorComponent,
    ImagePreloadDirective,
    FileDragDropDirective,
    UnderConstructionComponent,
    NotFoundComponent,
    NoAccessComponent,
    LocationViewerModalComponent,
    AutocompleteComponent,
    TimeagoPipe,
    Timeago2Pipe,
    NgxDatePipe,
    NavigationTabsComponent,
    NavigationModalButtonComponent,
    WorkflowViewerComponent,
  ]
})
export class SharedModule { }
