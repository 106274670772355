<div class="bpmn-container">
    <div #bpmnModelerRef 
        class="bpmnModeler-container">
    </div>

    <div class="d-flex justify-content-end mt-4">
        <div class="d-flex flex-column">
            <button
                class="mb-3 cursor-pointer btn btn-sm btn-secondary"
                title="Reset Zoom"
                (click)="zoomReset()">
                <i class="bi-arrow-counterclockwise" aria-hidden="true"></i>
            </button>
            <button
                class="btn cursor-pointer btn-sm btn-outline-primary"
                title="Zoom In"
                (click)="zoom()">
                <i class="bi-plus" aria-hidden="true"></i>
            </button>
            <button
                class="btn cursor-pointer btn-sm btn-outline-primary"
                title="Zoom Out"
                (click)="zoomOut()">
                <i class="bi-dash" aria-hidden="true"></i>
            </button>
        </div>
    </div>
</div>