import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import NavigatedViewer from 'bpmn-js/lib/NavigatedViewer';
import Viewer from 'bpmn-js/lib/Viewer';
import { Observable, from } from 'rxjs';
import { dummyBPMN1Flow } from 'src/app/home/module-project/document-management/workflows/workflows.component';

@Component({
  selector: 'app-workflow-viewer',
  templateUrl: './workflow-viewer.component.html',
  styleUrls: ['./workflow-viewer.component.css']
})
export class WorkflowViewerComponent implements OnInit, OnChanges {
  @Input() workflow:any;
  @ViewChild('bpmnModelerRef', { static: true }) private bpmnModelerRef: ElementRef | undefined;
  private bpmnJS: Viewer;

  constructor() {
    this.bpmnJS = new NavigatedViewer({
      container: this.bpmnModelerRef?.nativeElement,
      additionalModules: []
    })
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.workflow){
      this.bpmnJS.attachTo(this.bpmnModelerRef!.nativeElement);
      this.importDiagram(changes.workflow.currentValue).subscribe({
        next: (res) => console.log(res),
        error: (e) => console.log(e)
      });
    }
  }

  private importDiagram(xml: string): Observable<{warnings: Array<any>}> {
    return from(this.bpmnJS.importXML(xml) as Promise<{warnings: Array<any>}>);
  }

  // @ts-ignore
  zoom = () => this.bpmnJS?.get("zoomScroll")?.stepZoom(1);
  // @ts-ignore
  zoomOut = () => this.bpmnJS?.get("zoomScroll")?.stepZoom(-1);
  // @ts-ignore
  zoomReset = () => this.bpmnJS?.get("zoomScroll")?.reset();

}
